var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"GameCollapse",style:(_vm.isShowTitleBlock ? '' : 'border-bottom:0px;')},[(_vm.isShowTitleBlock)?_c('div',{staticClass:"collapseTitleBlock",on:{"click":_vm.clickArrow}},[_c('div',{staticClass:"leftArrowBlock"},[_c('i',{class:_vm.arrowIconJudge})]),_c('div',{staticClass:"rightLeagueNameBlock"},[_vm._v(" "+_vm._s(_vm.source.LeagueNameStr)+" ")])]):_vm._e(),(!_vm.isCollapse)?[(_vm.isBoldGame)?_vm._l((_vm.boldTeamData),function(teamData,teamIndex){return _c('div',{key:`${teamIndex}`,staticClass:"boldTablePanel"},[_c('div',{staticClass:"boldTableTitleBlock"},[_c('div',{staticClass:"timeBlock"},[_c('div',{staticClass:"timeText"},[_vm._v(_vm._s(_vm.$lib.timeFormatMMDD(teamData.ScheduleTimeStr)))]),_c('div',{staticClass:"timeText"},[_vm._v(_vm._s(_vm.$lib.timeFormatHHmm(teamData.ScheduleTimeStr)))])]),_c('div',{staticClass:"teamBlock"},[(teamData.hasOwnProperty('HomeTeamStr'))?_c('div',{staticClass:"teamText"},[_vm._v(_vm._s(teamData.HomeTeamStr))]):_vm._e(),_c('div',{staticClass:"teamText"},[_vm._v("vs")]),(teamData.hasOwnProperty('AwayTeamStr'))?_c('div',{staticClass:"teamText"},[_vm._v(" "+_vm._s(teamData.AwayTeamStr))]):_vm._e()]),_c('div',{staticClass:"star",class:_vm.starCSSJudge(teamData.EvtID),on:{"click":function($event){return _vm.addFavoriteHandler(teamData.EvtID)}}})]),_c('div',{staticClass:"boldTableBetList"},[_c('div',{staticClass:"boldTableBetListLeftContainer"},_vm._l((_vm.boldRenderLeftTemplateFilter),function(OULine,OULineIndex){return _c('div',{key:OULineIndex,staticClass:"boldTableBetBlock",class:_vm.boldTableBetBlockIsSelect(teamData.boldOddMapData[OULine], OULine),style:(_vm.boldFilterType ? '' : 'width:16.6666%'),on:{"click":function($event){_vm.goBoldBet(
                  _vm.boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]),
                  teamData.boldOddMapData[OULine],
                  teamData,
                  $event
                )}}},[_c('div',{staticClass:"betBlockTop"},[_vm._v(_vm._s(OULine)+" ")]),_c('div',{staticClass:"betBlockBottom"},[_vm._v(" "+_vm._s(_vm.boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]))+" ")])])}),0),_c('div',{staticClass:"boldTableBetListRightContainer"},_vm._l((_vm.boldRenderRightTemplateFilter),function(OULine,OULineIndex){return _c('div',{key:OULineIndex,staticClass:"boldTableBetBlock",class:_vm.boldTableBetBlockIsSelect(teamData.boldOddMapData[OULine], OULine),style:(_vm.boldFilterType ? '' : 'width:20%'),on:{"click":function($event){_vm.goBoldBet(
                  _vm.boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]),
                  teamData.boldOddMapData[OULine],
                  teamData,
                  $event
                )}}},[_c('div',{staticClass:"betBlockTop"},[_vm._v(_vm._s(_vm.boldOULineShow(OULine))+" ")]),_c('div',{staticClass:"betBlockBottom"},[_vm._v(" "+_vm._s(_vm.boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]))+" ")])])}),0)])])}):(_vm.isFix)?_vm._l((_vm.sourceTeamData),function(teamData,teamIndex){return _c('table',{key:teamIndex,staticClass:"normalTablePanel"},[_c('tbody',[(teamData.EvtStatus === 1)?[_c('tr',[_c('td',{staticClass:"FirstCatNameBlock"},[_c('div',{staticClass:"leftTimeBlock"},[(_vm.selectGameType === 2)?[_c('div',{staticClass:"timeRow light"},[_vm._v(" "+_vm._s(teamData.TimeAct)+" ")])]:[_c('div',{staticClass:"timeRow"},[_vm._v(" "+_vm._s(_vm.$lib.timeFormatMMDD(teamData.ScheduleTimeStr))+" ")]),_c('div',{staticClass:"timeRow"},[_vm._v(" "+_vm._s(_vm.$lib.timeFormatHHmm(teamData.ScheduleTimeStr))+" ")])]],2),_c('div',{staticClass:"centerTeamBlock"},[_c('div',{staticClass:"teamRow"},[_vm._v(" "+_vm._s(teamData.HomeTeamStr)+" ")]),_c('div',{staticClass:"teamRow"},[_vm._v(" "+_vm._s(teamData.AwayTeamStr)+" ")])]),(_vm.selectGameType === 2)?_c('div',{staticClass:"scoreBlock"},[_c('div',{staticClass:"homeScore",class:{ light: teamData.HomeScore > teamData.AwayScore }},[_vm._v(" "+_vm._s(teamData.HomeScore)+" ")]),_c('div',{staticClass:"awayScore",class:{ light: teamData.AwayScore > teamData.HomeScore }},[_vm._v(" "+_vm._s(teamData.AwayScore)+" ")])]):_vm._e(),_c('div',{staticClass:"rightFavoriteBlock"},[_c('div',{staticClass:"star",class:_vm.starCSSJudge(teamData.EvtID),on:{"click":function($event){return _vm.addFavoriteHandler(teamData.EvtID)}}})])]),_vm._l((teamData.Wager),function(wagerData,wagerIndex){return _c('td',{key:wagerIndex,staticClass:"GameTableHeaderOtherTD",attrs:{"set":((_vm.oddsData = wagerData.Odds[0]),
                  (_vm.odds = _vm.oddsData.DrewOdds),
                  (_vm.GameID = _vm.wagerRoIndexToGameID(wagerData, 0)))}},[_c('div',{staticClass:"WagerList fix"},[(wagerData.isNoData || _vm.oddsData.Status !== 1)?[_c('div',{staticClass:"WagerRow fix"})]:[_c('div',{staticClass:"WagerRow fix",class:_vm.isFixWagerSelectd(_vm.GameID),on:{"click":function($event){return _vm.goFixBet(wagerData, teamData, $event)}}},[_c('div',{staticClass:"WagerCenterItem fix"},[(_vm.odds > _vm.limit)?_c('Odd',{attrs:{"OddValue":_vm.odds,"UniqueID":`${_vm.GameID}`}}):_vm._e()],1)])]],2)])})],2)]:_vm._e()],2)])}):_vm._l((_vm.sourceTeamData),function(teamData,teamIndex){return _c('table',{key:teamIndex,staticClass:"normalTablePanel"},[_c('tbody',[(teamData.EvtStatus === 1)?_vm._l((teamData.Row),function(_,rowIndex){return _c('tr',{key:`${teamIndex}-${rowIndex}`},[(_vm.selectCatID === _vm.championWagerKey)?_c('td',{staticClass:"FirstCatNameBlock"},[_c('div',{staticClass:"leftTimeBlock"}),_c('div',{staticClass:"centerTeamBlock"},[_c('div',{staticClass:"teamRow",attrs:{"title":teamData.HomeTeamStr}},[_vm._v(" "+_vm._s(teamData.HomeTeamStr)+" ")])])]):_c('td',{staticClass:"FirstCatNameBlock"},[_c('div',{staticClass:"leftTimeBlock"},[((rowIndex === 0 && _vm.selectGameType !== 2) || _vm.isSingleScore)?[_c('div',{staticClass:"timeRow"},[_vm._v(" "+_vm._s(_vm.$lib.timeFormatMMDD(teamData.ScheduleTimeStr))+" ")]),_c('div',{staticClass:"timeRow"},[_vm._v(" "+_vm._s(_vm.$lib.timeFormatHHmm(teamData.ScheduleTimeStr))+" ")])]:[(rowIndex === 0)?_c('div',{staticClass:"timeRow light"},[_vm._v(" "+_vm._s(teamData.TimeAct)+" ")]):_vm._e()]],2),_c('div',{staticClass:"centerTeamBlock"},[(
                      teamData.hasOwnProperty('HomePtAndNation') &&
                      teamData.HomePtAndNation.length !== 0
                    )?[_c('div',{staticClass:"teamRow",attrs:{"title":`${teamData.HomePtAndNation[0]}-${teamData.HomePtAndNation[1]} / ${teamData.HomePtAndNation[2]}-${teamData.HomePtAndNation[3]}`}},[_vm._v(" "+_vm._s(teamData.HomePtAndNation[0])+" "),_c('span',{staticClass:"teamPt"},[_vm._v("-"+_vm._s(teamData.HomePtAndNation[1]))]),_c('span',[_vm._v(" /")]),_vm._v(" "+_vm._s(teamData.HomePtAndNation[2])+" "),_c('span',{staticClass:"teamPt"},[_vm._v("-"+_vm._s(teamData.HomePtAndNation[3]))])])]:_vm._e(),(
                      teamData.hasOwnProperty('AwayPtAndNation') &&
                      teamData.AwayPtAndNation.length !== 0
                    )?[_c('div',{staticClass:"teamRow",attrs:{"title":`${teamData.AwayPtAndNation[0]}-${teamData.AwayPtAndNation[1]} / ${teamData.AwayPtAndNation[2]}-${teamData.AwayPtAndNation[3]}`}},[_vm._v(" "+_vm._s(teamData.AwayPtAndNation[0])+" "),_c('span',{staticClass:"teamPt"},[_vm._v("-"+_vm._s(teamData.AwayPtAndNation[1]))]),_c('span',[_vm._v(" /")]),_vm._v(" "+_vm._s(teamData.AwayPtAndNation[2])+" "),_c('span',{staticClass:"teamPt"},[_vm._v("-"+_vm._s(teamData.AwayPtAndNation[3]))])])]:_vm._e(),(teamData.hasOwnProperty('AwayTeamStr') && teamData.AwayTeamStr === '.')?[(teamData.hasOwnProperty('HomeTeamStr'))?_c('div',{staticClass:"teamRow",attrs:{"title":teamData.HomeTeamStr}},[_vm._v(" "+_vm._s(teamData.HomeTeamStr)+" "),(teamData.hasOwnProperty('HomePtNameStr') && _vm.isShowPTName)?_c('span',{staticClass:"teamPt"},[_vm._v(_vm._s(teamData.HomePtNameStr))]):_vm._e()]):_vm._e()]:(
                      teamData.hasOwnProperty('HomeTeamStr') &&
                      teamData.hasOwnProperty('AwayTeamStr') &&
                      teamData.HomeTeamStr === '.'
                    )?[_c('div',{staticClass:"teamRow",attrs:{"title":teamData.AwayTeamStr}},[_vm._v(" "+_vm._s(teamData.AwayTeamStr)+" "),(teamData.hasOwnProperty('AwayPtNameStr') && _vm.isShowPTName)?_c('span',{staticClass:"teamPt"},[_vm._v(_vm._s(teamData.AwayPtNameStr))]):_vm._e()])]:[(_vm.isSingleScore)?[(
                          rowIndex % 2 === 0 &&
                          teamData.hasOwnProperty('HomeTeamStr') &&
                          teamData.hasOwnProperty('AwayTeamStr')
                        )?_c('div',{staticClass:"teamRow",attrs:{"title":teamData.HomeTeamStr}},[_vm._v(" "+_vm._s(teamData.HomeTeamStr)+" "),(
                            teamData.hasOwnProperty('HomePtNameStr') &&
                            teamData.HomePtNameStr !== ''
                          )?_c('span',{staticClass:"teamPt"},[_vm._v(" -"+_vm._s(teamData.HomePtNameStr)+" ")]):_vm._e()]):_c('div',{staticClass:"teamRow",attrs:{"title":teamData.AwayTeamStr}},[(teamData.hasOwnProperty('AwayTeamStr'))?_c('span',[_vm._v(" "+_vm._s(teamData.AwayTeamStr))]):_vm._e(),(
                            teamData.hasOwnProperty('AwayPtNameStr') &&
                            teamData.AwayPtNameStr !== ''
                          )?_c('span',{staticClass:"teamPt"},[_vm._v(" -"+_vm._s(teamData.AwayPtNameStr)+" ")]):_vm._e()])]:[(!teamData.SetFlag)?[(teamData.hasOwnProperty('AwayTeamStr'))?_c('div',{staticClass:"teamRow",attrs:{"title":teamData.AwayTeamStr}},[_vm._v(" "+_vm._s(teamData.AwayTeamStr)+" "),(
                              teamData.hasOwnProperty('AwayPtNameStr') &&
                              teamData.AwayPtNameStr !== '' &&
                              _vm.isShowPTName
                            )?_c('span',{staticClass:"teamPt"},[_vm._v(" -"+_vm._s(teamData.AwayPtNameStr)+" ")]):_vm._e()]):_vm._e(),(teamData.hasOwnProperty('HomeTeamStr'))?_c('div',{staticClass:"teamRow",attrs:{"title":teamData.HomeTeamStr}},[_vm._v(" "+_vm._s(teamData.HomeTeamStr)+" "),(
                              teamData.hasOwnProperty('HomePtNameStr') &&
                              teamData.HomePtNameStr !== '' &&
                              _vm.isShowPTName
                            )?_c('span',{staticClass:"teamPt"},[_vm._v(" -"+_vm._s(teamData.HomePtNameStr)+" ")]):_vm._e()]):_vm._e()]:[(teamData.hasOwnProperty('HomeTeamStr'))?_c('div',{staticClass:"teamRow",attrs:{"title":teamData.HomeTeamStr}},[_vm._v(" "+_vm._s(teamData.HomeTeamStr)+" "),(
                              teamData.hasOwnProperty('HomePtNameStr') &&
                              teamData.HomePtNameStr !== '' &&
                              _vm.isShowPTName
                            )?_c('span',{staticClass:"teamPt"},[_vm._v(" -"+_vm._s(teamData.HomePtNameStr)+" ")]):_vm._e()]):_vm._e(),(teamData.hasOwnProperty('AwayTeamStr'))?_c('div',{staticClass:"teamRow",attrs:{"title":teamData.AwayTeamStr}},[_vm._v(" "+_vm._s(teamData.AwayTeamStr)+" "),(
                              teamData.hasOwnProperty('AwayPtNameStr') &&
                              teamData.AwayPtNameStr !== '' &&
                              _vm.isShowPTName
                            )?_c('span',{staticClass:"teamPt"},[_vm._v(" -"+_vm._s(teamData.AwayPtNameStr)+" ")]):_vm._e()]):_vm._e()]]],(teamData.hasDrewOdds && rowIndex === 0)?_c('div',{staticClass:"teamRow"},[_vm._v(" "+_vm._s(_vm.$t('Common.DrewOdd'))+" ")]):_vm._e()],2),(rowIndex === 0 && _vm.selectGameType === 2)?_c('div',{staticClass:"scoreBlock"},[(teamData.SetFlag)?[_c('div',{staticClass:"homeScore",class:teamData.HomeScore > teamData.AwayScore ? 'light' : ''},[_vm._v(" "+_vm._s(teamData.HomeScore)+" ")]),_c('div',{staticClass:"awayScore",class:teamData.AwayScore > teamData.HomeScore ? 'light' : ''},[_vm._v(" "+_vm._s(teamData.AwayScore)+" ")])]:[_c('div',{staticClass:"awayScore",class:teamData.AwayScore > teamData.HomeScore ? 'light' : ''},[_vm._v(" "+_vm._s(teamData.AwayScore)+" ")]),_c('div',{staticClass:"homeScore",class:teamData.HomeScore > teamData.AwayScore ? 'light' : ''},[_vm._v(" "+_vm._s(teamData.HomeScore)+" ")])]],2):_vm._e(),(rowIndex === 0 || _vm.isSingleScore)?_c('div',{staticClass:"rightFavoriteBlock"},[_c('div',{staticClass:"star",class:_vm.starCSSJudge(teamData.EvtID),on:{"click":function($event){return _vm.addFavoriteHandler(teamData.EvtID)}}})]):_vm._e()]),(_vm.selectCatID === _vm.championWagerKey)?[_vm._l((_vm.teamWagerDataFilterLimit(teamData)),function(wagerData,wagerIndex){return _c('td',{key:wagerIndex,staticClass:"GameTableHeaderOtherTD",attrs:{"set":((_vm.sportData = _vm.$SportLib.WagerDataToShowData(
                      teamData.SetFlag,
                      wagerData,
                      rowIndex
                    )),
                    (_vm.isShowDrewOdd = teamData.hasDrewOdds && rowIndex === 0),
                    (_vm.GameID = _vm.wagerRoIndexToGameID(wagerData, rowIndex)))}},[_c('div',{staticClass:"WagerList"},[(wagerData.isNoData)?[_c('div',{staticClass:"WagerRow"})]:[_c('div',{staticClass:"WagerRow",class:_vm.WagerRowIsSelectInCartCSS(
                            _vm.GameID,
                            _vm.sportData.topPlayOdd,
                            _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                              .topWagerPos
                          ),on:{"click":function($event){_vm.goBet(
                            _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                              .topPlayOdd,
                            teamData,
                            wagerData,
                            rowIndex,
                            _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                              .topWagerPos,
                            $event
                          )}}},[_c('div',{staticClass:"WagerCenterItem"},[(_vm.sportData.topPlayOdd > _vm.limit)?_c('Odd',{attrs:{"OddValue":_vm.sportData.topPlayOdd,"UniqueID":`${_vm.GameID}-0`}}):_vm._e()],1)])]],2)])}),_vm._m(0,true)]:_vm._l((_vm.teamWagerDataFilterLimit(teamData)),function(wagerData,wagerIndex){return _c('td',{key:wagerIndex,staticClass:"GameTableHeaderOtherTD",attrs:{"set":((_vm.sportData = _vm.$SportLib.WagerDataToShowData(
                      teamData.SetFlag,
                      wagerData,
                      rowIndex
                    )),
                    (_vm.isShowDrewOdd = teamData.hasDrewOdds && rowIndex === 0),
                    (_vm.GameID = _vm.wagerRoIndexToGameID(wagerData, rowIndex)))}},[_c('div',{staticClass:"WagerList"},[(wagerData.isNoData)?[(_vm.isShowDrewOdd)?[_c('div',{staticClass:"WagerRow"}),_c('div',{staticClass:"WagerRow"}),_c('div',{staticClass:"WagerRow"})]:[_c('div',{staticClass:"WagerRow"}),_c('div',{staticClass:"WagerRow"})]]:[(_vm.sportData.layoutType === 'single')?[_c('div',{staticClass:"WagerRow",class:_vm.WagerRowIsSelectInCartCSS(
                              _vm.GameID,
                              _vm.sportData.topPlayOdd,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .topWagerPos
                            ),on:{"click":function($event){_vm.goBet(
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .topPlayOdd,
                              teamData,
                              wagerData,
                              rowIndex,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .topWagerPos,
                              $event
                            )}}},[_c('div',{staticClass:"WagerCenterItem"},[(_vm.sportData.topPlayOdd > _vm.limit)?_c('Odd',{attrs:{"OddValue":_vm.sportData.topPlayOdd,"UniqueID":`${_vm.GameID}-0`}}):_vm._e()],1)]),_c('div',{staticClass:"WagerRow",class:_vm.WagerRowIsSelectInCartCSS(
                              _vm.GameID,
                              _vm.sportData.bottomPlayOdd,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .bottomWagerPos
                            ),on:{"click":function($event){_vm.goBet(
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .bottomPlayOdd,
                              teamData,
                              wagerData,
                              rowIndex,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .bottomWagerPos,
                              $event
                            )}}},[_c('div',{staticClass:"WagerCenterItem"},[(_vm.sportData.bottomPlayOdd > _vm.limit)?_c('Odd',{attrs:{"OddValue":_vm.sportData.bottomPlayOdd,"UniqueID":`${_vm.GameID}-1`}}):_vm._e()],1)])]:[_c('div',{staticClass:"WagerRow",class:_vm.WagerRowIsSelectInCartCSS(
                              _vm.GameID,
                              _vm.sportData.topPlayOdd,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .topWagerPos
                            ),on:{"click":function($event){_vm.goBet(
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .topPlayOdd,
                              teamData,
                              wagerData,
                              rowIndex,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .topWagerPos,
                              $event
                            )}}},[_c('div',{staticClass:"WagerItem"},[_vm._v(" "+_vm._s(_vm.sportData.topPlayMethod)+" ")]),_c('div',{staticClass:"WagerItem"},[(_vm.sportData.topPlayOdd > _vm.limit)?_c('Odd',{attrs:{"OddValue":_vm.sportData.topPlayOdd,"UniqueID":`${_vm.GameID}-0`}}):_vm._e()],1)]),_c('div',{staticClass:"WagerRow",class:_vm.WagerRowIsSelectInCartCSS(
                              _vm.GameID,
                              _vm.sportData.bottomPlayOdd,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .bottomWagerPos
                            ),on:{"click":function($event){_vm.goBet(
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .bottomPlayOdd,
                              teamData,
                              wagerData,
                              rowIndex,
                              _vm.$SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                .bottomWagerPos,
                              $event
                            )}}},[_c('div',{staticClass:"WagerItem"},[_vm._v(" "+_vm._s(_vm.sportData.bottomPlayMethod)+" ")]),_c('div',{staticClass:"WagerItem"},[(_vm.sportData.bottomPlayOdd > _vm.limit)?_c('Odd',{attrs:{"OddValue":_vm.sportData.bottomPlayOdd,"UniqueID":`${_vm.GameID}-1`}}):_vm._e()],1)])],(_vm.isShowDrewOdd)?[(
                            wagerData.Odds[0].DrewOdds === '0' ||
                            wagerData.Odds[0].DrewOdds === '0.00' ||
                            wagerData.Odds[0].Status !== 1
                          )?[_c('div',{staticClass:"WagerRow"})]:[_c('div',{staticClass:"WagerRow",class:_vm.WagerRowIsSelectInCartCSS(
                                _vm.GameID,
                                wagerData.Odds[0].DrewOdds,
                                _vm.$SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  wagerData,
                                  rowIndex
                                ).drewWagerPos
                              ),on:{"click":function($event){_vm.goBet(
                                wagerData.Odds[0].DrewOdds,
                                teamData,
                                wagerData,
                                rowIndex,
                                _vm.$SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  wagerData,
                                  rowIndex
                                ).drewWagerPos,
                                $event
                              )}}},[_c('div',{staticClass:"WagerCenterItem"},[(wagerData.Odds[0].DrewOdds > _vm.limit)?_c('Odd',{attrs:{"OddValue":wagerData.Odds[0].DrewOdds,"UniqueID":`${_vm.GameID}-2`}}):_vm._e()],1)])]]:_vm._e()]],2)])}),(teamData.MoreCount !== -1 && _vm.selectCatID !== _vm.championWagerKey)?_c('td',{staticClass:"GameTableHeaderMoreTD",class:_vm.isGameTableHeaderMoreSelect(teamData, rowIndex)},[(rowIndex === 0)?_c('div',{staticClass:"moreGame",on:{"click":function($event){return _vm.moreGameClickHandler(teamData)}}},[_c('img',{attrs:{"src":require("@/assets/img/common/moreGameIcon.svg")}}),_vm._v(" "+_vm._s(teamData.MoreCount)+" ")]):_vm._e()]):_vm._e()],2)}):_vm._e()],2)])})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"GameTableHeaderOtherTD"},[_c('div',{staticClass:"WagerList"},[_c('div',{staticClass:"WagerRow"})])])
}]

export { render, staticRenderFns }