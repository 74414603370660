<template>
  <div id="HistoryRecord">
    <div class="Record_head">
      <h3>{{ $t('HistoryRecord.BetHistoryRecord') }}</h3>
    </div>
    <div class="Record_top">
      <ul>
        <li
          @click="
            active = 0;
            refreshAPI();
          "
          :class="active === 0 ? 'active' : ''"
        >
          {{ $t('GamesBetInfo.NotCountBet') }}
        </li>
        <li
          @click="
            active = 1;
            refreshAPI();
          "
          :class="active !== 0 ? 'active' : ''"
        >
          {{ $t('HistoryRecord.IsCountBet') }}
        </li>
      </ul>
      <div>
        <el-button v-show="active === 2" size="mini" @click="active = 1" icon="el-icon-arrow-left">
          {{ $t('Common.Return') }}
        </el-button>
        <el-button type="primary" size="mini" @click="refreshAPI">
          {{ $t('Common.Update') }}
        </el-button>
      </div>
    </div>
    <div class="Record_main" ref="Record_main">
      <div class="Record_mainContainer">
        <!-- 未結算注單 -->
        <template v-if="active === 0">
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th width="200">{{ $t('HistoryRecord.BetMessage') }}</th>
              <th>{{ $t('Common.BetContent') }}</th>
              <th width="150">{{ $t('HistoryRecord.BetAmount') }}</th>
              <th width="150">{{ $t('Common.CanWin') }}</th>
            </tr>
            <tr v-if="getBetHistoryData.length === 0">
              <td class="NoDataTD" colspan="5">
                <div class="NoData">尚無資料</div>
              </td>
            </tr>
            <tr
              class="rt_data"
              v-for="(item, i) in getBetHistoryData"
              :key="i"
              :set="(betitem = item.dataBet[0])"
            >
              <td class="rt_info">
                <ul>
                  <li>
                    <span>{{ $t('Common.DownBet') }} : </span>
                    <span>{{ item.BetTimeStr }}</span>
                  </li>
                  <li v-if="item.BetType === 1">
                    <span>{{ $t('Common.Game') }} : </span>
                    <span>{{ betitem?.ScheduleTimeStr }}</span>
                  </li>
                  <li v-else>
                    <span>{{ $t('HistoryRecord.TypeStray') }} </span>
                    <span> {{ item.dataBet.length }}{{ $t('Common.string') }} 1 x 1 </span>
                  </li>
                  <li>
                    <span>{{ $t('Common.BetID') }} : </span>
                    <span>{{ item.TicketID }}</span>
                  </li>
                  <li>
                    <span>{{ $t('Common.Odd') }} :</span>
                    <span>{{ $t('GamesSetup.NotIncludePrincipal') }}</span>
                  </li>
                </ul>
              </td>
              <td v-if="item.BetType === 1">
                <ul>
                  <li>
                    {{ betitem.CatName }}
                    <template v-if="betitem.GameType === 2">
                      {{ betitem.GameTypeName }}
                    </template>
                    - {{ betitem.LeagueName }}
                    <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                  </li>

                  <!-- 賽馬賽狗-->
                  <li v-if="[72].includes(betitem.CatID)">
                    <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                    {{ betitem.HomeTeam }}
                  </li>
                  <!-- 彩球 -->
                  <li v-else-if="[83].includes(betitem.CatID)">
                    <template v-if="betitem.HomeTeam">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                    </template>
                    <template v-else-if="betitem.AwayTeam">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </template>
                  </li>
                  <!-- 指數 -->
                  <li v-else-if="[84].includes(betitem.CatID)">
                    <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                    {{ betitem.AwayTeam }}
                  </li>
                  <!-- 冠軍 -->
                  <li v-else-if="championWagerIDs.includes(betitem.WagerTypeID)">
                    {{ betitem.HomeTeam }}
                  </li>
                  <!-- 單隊總分 -->
                  <li v-else-if="[131, 132].includes(betitem.WagerTypeID)">
                    <template v-if="betitem.HomeTeam">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                    </template>
                    <template v-else-if="betitem.AwayTeam">
                      <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </template>
                  </li>
                  <li v-else>
                    <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                    {{ betitem.HomeTeam }}
                    <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                    vs
                    <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                    {{ betitem.AwayTeam }}
                  </li>
                  <li>
                    {{ $t('Common.Bet') }} :
                    <span
                      v-if="
                        betitem.GameType === 2 &&
                        [1, 31].includes(betitem.CatID) &&
                        [101].includes(betitem.WagerTypeID)
                      "
                      class="ScoreColor"
                      style="display: inline"
                    >
                      {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                    </span>
                    <span class="betTeamColor">
                      {{ betitem.betname }}
                      <span v-if="!championWagerIDs.includes(betitem.WagerTypeID)" class="oddColor">
                        {{ betitem.CutLine }}
                      </span>
                    </span>
                    @
                    <span class="oddColor"> {{ betitem.PayoutOddsStr }} </span>
                  </li>
                </ul>
              </td>
              <td class="rt_fs" v-if="item.BetType === 99">
                <div class="rt_fs_list" v-for="(betitem, y) in item.dataBet" :key="y">
                  <div>{{ y + 1 }}</div>
                  <ul>
                    <li>
                      {{ betitem.CatName }} - {{ betitem.LeagueName }}
                      <template v-if="betitem.ItemName"> - {{ betitem.ItemName }}</template>
                    </li>
                    <!-- 賽馬賽狗-->
                    <li v-if="[72].includes(betitem.CatID)">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                    </li>
                    <!-- 彩球 -->
                    <li v-else-if="[83].includes(betitem.CatID)">
                      <template v-if="betitem.HomeTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <!-- 指數 -->
                    <li v-else-if="[84].includes(betitem.CatID)">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </li>
                    <li v-else>
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      vs
                      <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </li>
                    <li>
                      {{ $t('Common.Bet') }} :
                      <span
                        v-if="
                          betitem.GameType === 2 &&
                          [1, 31].includes(betitem.CatID) &&
                          [101].includes(betitem.WagerTypeID)
                        "
                        class="ScoreColor"
                        style="display: inline"
                      >
                        {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                      </span>
                      <span class="betTeamColor">
                        {{ betitem.betname }}
                        <span
                          v-if="!championWagerIDs.includes(betitem.WagerTypeID)"
                          class="oddColor"
                        >
                          {{ betitem.CutLine }}
                        </span>
                      </span>
                      @
                      <span class="oddColor"> {{ betitem.PayoutOddsStr }} </span>
                    </li>
                    <li>
                      {{ $t('Common.GameTime') }} :
                      <span class="startGameTime"> {{ betitem?.ScheduleTimeStr }} </span>
                    </li>
                  </ul>
                </div>
              </td>
              <td class="rt_betval">
                {{ toCurrency(item.Amount) }}
              </td>
              <td class="rt_betval">
                {{ toCurrency(item.ToWin) }}
              </td>
            </tr>
            <tr class="rt_foot">
              <td colspan="2">{{ $t('Common.Total') }}</td>
              <td class="betSumTotal">
                {{ toCurrency(totalAmount) }}
              </td>
              <td>
                {{ toCurrency(totalWinAmount) }}
              </td>
            </tr>
          </table>

          <div class="footerPageBlock">
            <el-pagination
              class
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageData.CurrentPage"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="pageData.PageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.Count"
            >
            </el-pagination>
          </div>
        </template>

        <!-- 已結算注單 -->
        <table v-show="active === 1" border="0" cellspacing="0" cellpadding="0" class="weektable">
          <tr>
            <th>{{ $t('GameDate') }}</th>
            <th>{{ $t('HistoryRecord.BetAmount') }}</th>
            <th>{{ $t('Common.Result') }}</th>
          </tr>
          <tr
            v-for="(item, i) in weekData"
            :class="
              item.weekLang.indexOf($t('Common.Total')) > 0 || item.week === 128 ? 'week' : ''
            "
            :key="i"
          >
            <td> {{ item.accdate.substr(5) }} {{ item.weekLang }} </td>
            <td>
              {{ toCurrency(item.amount) }}
            </td>
            <td
              v-if="item.weekLang.indexOf($t('Common.Total')) > 0"
              :class="sumClassColorJudge(item.ResultAmount)"
            >
              {{ toCurrency(Number(item.ResultAmount).toFixed(2)) }}
            </td>
            <td v-else>
              <el-link
                type="primary"
                @click="goThisWeek(item.accdate)"
                :class="sumBlueClassColorJudge(item.ResultAmount)"
                style="text-decoration: underline"
              >
                {{ toCurrency(Number(item.ResultAmount).toFixed(2)) }}
              </el-link>
            </td>
          </tr>
        </table>

        <!-- 已結算注單詳細 -->
        <div v-show="active === 2">
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th width="185">{{ $t('HistoryRecord.BetMessage') }}</th>
              <th width="400">{{ $t('Common.BetContent') }}</th>
              <th width="100">{{ $t('HistoryRecord.BetAmount') }}</th>
              <th width="100">{{ $t('Common.CanWin') }}</th>
              <th width="100">{{ $t('Common.Result') }}</th>
            </tr>
          </table>

          <div v-for="(item, i) in gettodayDetails" :key="i">
            <table border="0" cellspacing="0" cellpadding="0" class="collapsetable">
              <tr @click="upactive(item.CatName)" style="cursor: pointer">
                <td width="585">
                  <i :class="item.active ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                  {{ item.CatName }}
                </td>
                <td width="100">
                  {{ toCurrency(item.Amounts) }}
                </td>
                <td width="100">
                  {{ toCurrency(item.canwins) }}
                </td>
                <td
                  width="100"
                  :class="parseInt(item.ResultAmounts) < 0 ? 'ScoreColor' : 'resultScore'"
                >
                  {{ toCurrency(item.ResultAmounts) }}
                </td>
              </tr>
            </table>

            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              v-show="item.active"
              v-for="(itemdata, y) in item.data"
              :key="y"
            >
              <tr
                class="rt_data"
                :class="
                  itemdata.StatusType === 'D' || itemdata.StatusType === 'V' ? 'DeleteBG' : ''
                "
              >
                <td width="185" class="rt_info">
                  <ul>
                    <li>
                      <span>{{ $t('Common.DownBet') }} : </span>
                      <span>{{ itemdata.BetTimeStr.slice(5, 16) }}</span>
                    </li>
                    <li v-if="itemdata.BetType === 1">
                      <span>{{ $t('Common.Game') }} : </span>
                      <span>{{ itemdata.dataBet[0]?.ScheduleTimeStr.slice(5, 16) }}</span>
                    </li>
                    <li v-else>
                      <span>{{ $t('HistoryRecord.TypeStray') }} </span>
                      <span> {{ itemdata.dataBet.length }}{{ $t('Common.string') }} 1 x 1 </span>
                    </li>
                    <li>
                      <span>{{ $t('Common.BetID') }} : </span>
                      <span>{{ itemdata.TicketID }}</span>
                    </li>
                    <li>
                      <span>{{ $t('Common.Odd') }} :</span>
                      <span>{{ $t('GamesSetup.NotIncludePrincipal') }}</span>
                    </li>
                  </ul>
                </td>
                <!-- 過關 -->
                <td
                  width="400"
                  class="rt_fs"
                  :class="['D', 'V'].includes(itemdata.StatusType) ? 'textLine' : ''"
                  v-if="itemdata.BetType === 99"
                >
                  <div class="rt_fs_list" v-for="(betitem, y) in itemdata.dataBet" :key="y">
                    <div>{{ y + 1 }}</div>
                    <ul v-if="betitem.ItemStatus === 'Y'">
                      <li :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''">
                        {{ betitem.CatName }} - {{ betitem.LeagueName }}
                        <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                      </li>
                      <!-- 賽馬賽狗 -->
                      <li
                        v-if="[72].includes(betitem.CatID)"
                        :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''"
                      >
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                      </li>
                      <!-- 彩球 -->
                      <li
                        v-else-if="[83].includes(betitem.CatID)"
                        :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''"
                      >
                        <template v-if="betitem.HomeTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.HomeTeam }}
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <!-- 指數 -->
                      <li
                        v-else-if="[84].includes(betitem.CatID)"
                        :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''"
                      >
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </li>
                      <li v-else :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        vs
                        <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </li>
                      <li :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''">
                        {{ $t('Common.Bet') }} :
                        <span
                          v-if="
                            betitem.GameType === 2 &&
                            [1, 31].includes(betitem.CatID) &&
                            [101].includes(betitem.WagerTypeID)
                          "
                          class="ScoreColor"
                          style="display: inline"
                        >
                          {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                        </span>
                        <span class="betTeamColor">
                          {{ betitem.betname }}
                          <span
                            v-if="!championWagerIDs.includes(betitem.WagerTypeID)"
                            class="oddColor"
                          >
                            {{ betitem.CutLine }}
                          </span>
                        </span>
                        @
                        <span class="oddColor"> {{ betitem.PayoutOddsStr }} </span>
                      </li>
                      <li :class="betitem.ItemStatus !== 'Y' ? 'cancel' : ''">
                        {{ $t('Common.GameTime') }} :
                        <span class="startGameTime"> {{ betitem?.ScheduleTimeStr }} </span>
                      </li>
                      <li v-if="betitem.ItemStatusContent">
                        <span style="color: red"> ({{ betitem.ItemStatusContent }}) </span>
                      </li>
                      <li v-if="betitem.ItemStatus !== 'Y'">
                        <span class="ScoreColor">比賽取消</span>
                      </li>
                    </ul>
                  </div>
                  <div class="Remarks" style="padding: 5px" v-if="itemdata.Remarks !== ''">
                    {{ itemdata.Remarks }}
                  </div>
                </td>
                <!-- 一般投注 -->
                <td
                  v-else
                  width="400"
                  :class="['D', 'V'].includes(itemdata.StatusType) ? 'textLine' : ''"
                  :set="(betitem = itemdata.dataBet[0])"
                >
                  <ul>
                    <li>
                      {{ betitem.CatName }}
                      <template v-if="betitem.GameType === 2">
                        {{ betitem.GameTypeName }}
                      </template>
                      - {{ betitem.LeagueName }}
                      <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                    </li>
                    <!-- 賽馬賽狗-->
                    <li v-if="[72].includes(betitem.CatID)">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                    </li>
                    <!-- 彩球 -->
                    <li v-else-if="[83].includes(betitem.CatID)">
                      <template v-if="betitem.HomeTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <!-- 指數 -->
                    <li v-else-if="[84].includes(betitem.CatID)">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </li>
                    <!-- 冠軍 -->
                    <li v-else-if="championWagerIDs.includes(betitem.WagerTypeID)">
                      {{ betitem.HomeTeam }}
                    </li>
                    <!-- 單隊總分 -->
                    <li v-else-if="[131, 132].includes(betitem.WagerTypeID)">
                      <template v-if="betitem.HomeTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <li v-else>
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      vs
                      <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </li>
                    <li>
                      {{ $t('Common.Bet') }} :
                      <span
                        class="ScoreColor"
                        v-if="
                          betitem.GameType === 2 &&
                          [1, 31].includes(betitem.CatID) &&
                          [101].includes(betitem.WagerTypeID)
                        "
                        style="display: inline"
                      >
                        {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                      </span>
                      <span class="betTeamColor">
                        {{ betitem.betname }}
                        <span
                          v-if="!championWagerIDs.includes(betitem.WagerTypeID)"
                          class="oddColor"
                        >
                          {{ betitem.CutLine }}
                        </span>
                      </span>
                      @
                      <span class="oddColor">{{ betitem.PayoutOddsStr }}</span>
                    </li>
                    <li v-if="betitem.ItemStatusContent">
                      <span style="color: red"> ({{ betitem.ItemStatusContent }}) </span>
                    </li>
                    <div class="Remarks" v-if="itemdata.Remarks !== ''">
                      {{ itemdata.Remarks }}
                    </div>
                  </ul>
                </td>
                <td width="100" class="rt_betval">
                  {{ toCurrency(itemdata.Amount) }}
                </td>
                <td width="100" class="rt_betval" v-if="itemdata.BetType === 1">
                  {{ toCurrency(Math.floor(itemdata.Amount * betitem.PayoutOddsStr)) }}
                </td>
                <td width="100" class="rt_betval" v-else>
                  {{ toCurrency(itemdata.ToWin) }}
                </td>
                <td
                  width="100"
                  class="rt_betval"
                  :class="parseInt(itemdata.ResultAmount) < 0 ? 'ScoreColor' : 'resultScore'"
                >
                  {{ toCurrency(itemdata.ResultAmount) }}
                </td>
              </tr>
            </table>
          </div>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr class="rt_foot">
              <td width="585">
                {{ $t('Common.Total') }}
              </td>
              <td width="100" class="betSumTotal">
                {{ toCurrency(gettotal.Amounts) }}
              </td>
              <td width="100">
                {{ toCurrency(gettotal.canwins) }}
              </td>
              <td width="100" :class="this.sumClassColorJudge(gettotal.ResultAmounts)">
                {{ toCurrency(gettotal.ResultAmounts) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { championWagerIDs } from '@/config';
  import { toCurrency } from '@/config/converter';

  export default {
    data() {
      return {
        active: 0,
        betHistoryData: [],
        weekData: [],
        todayDetails: [],
        pageData: {
          CurrentPage: 1,
          TotalPage: 1,
          PageSize: 100,
          Count: 0,
        },
        lastGoWeekTime: '',
        championWagerIDs,
      };
    },
    computed: {
      totalAmount() {
        let total = 0;
        this.betHistoryData.forEach((item) => {
          total += item.Amount;
        });
        return total;
      },
      totalWinAmount() {
        let total = 0;
        this.betHistoryData.forEach((item) => {
          total += item.ToWin;
        });
        return total;
      },
      getBetHistoryData() {
        this.betHistoryData.forEach((item, i) => {
          if (item.BetType === 1) {
            if (item.dataBet[0].WagerPosName === this.$t('Common.HomeTeam')) {
              this.betHistoryData[i].dataBet[0].betname = item.dataBet[0].HomeTeam;
            } else if (item.dataBet[0].WagerPosName === this.$t('Common.AwayTeam')) {
              this.betHistoryData[i].dataBet[0].betname = item.dataBet[0].AwayTeam;
            } else {
              this.betHistoryData[i].dataBet[0].betname = item.dataBet[0].WagerPosName;
            }
          } else {
            item.dataBet.forEach((betList, y) => {
              if (betList.WagerPosName === this.$t('Common.HomeTeam')) {
                this.betHistoryData[i].dataBet[y].betname = betList.HomeTeam;
              } else if (betList.WagerPosName === this.$t('Common.AwayTeam')) {
                this.betHistoryData[i].dataBet[y].betname = betList.AwayTeam;
              } else {
                this.betHistoryData[i].dataBet[y].betname = item.dataBet[y].WagerPosName;
              }
            });
          }
        });
        return this.betHistoryData;
      },
      gettodayDetails() {
        return this.todayDetails;
      },
      gettotal() {
        const total = { Amounts: 0, ResultAmounts: 0, canwins: 0 };

        this.gettodayDetails.forEach((item) => {
          total.Amounts = this.$lib.trunc(total.Amounts + item.Amounts);
          total.ResultAmounts = this.$lib.trunc(total.ResultAmounts + item.ResultAmounts);
          total.canwins = this.$lib.trunc(total.canwins + item.canwins);
        });
        return total;
      },
    },
    methods: {
      toCurrency,
      isShowScore(catID, GameType) {
        return (catID === 1 || catID === 31) && GameType === 2;
      },
      refreshAPI() {
        if (this.active === 0) {
          this.getTicketApi();
        } else if (this.active === 1) {
          this.getTicketStatsApi();
        } else if (this.active === 2) {
          this.getBetHistoryDetail(
            this.lastGoWeekTime + ' 00:00:00',
            this.lastGoWeekTime + ' 23:59:59'
          );
        }
      },
      sumClassColorJudge(num) {
        if (parseInt(num) < 0) {
          return 'ScoreColor';
        } else if (parseInt(num) === 0) {
          return 'whiteColor';
        } else {
          return 'resultScore';
        }
      },
      sumBlueClassColorJudge(num) {
        if (parseInt(num) < 0) {
          return 'ScoreColor';
        } else if (parseInt(num) === 0) {
          return 'linkColor';
        } else {
          return 'resultScore';
        }
      },
      handleSizeChange(val) {
        this.pageData.CurrentPage = 1;
        this.pageData.PageSize = val;
        this.getTicketApi();
      },
      handleCurrentChange(val) {
        this.pageData.CurrentPage = val;
        this.getTicketApi();
      },
      upactive(CatName) {
        this.gettodayDetails.forEach((item) => {
          if (item.CatName === CatName) {
            item.active = !item.active;
          }
        });
        this.$forceUpdate();
      },
      goThisWeek(time) {
        this.active = 2;
        this.lastGoWeekTime = time;
        this.getBetHistoryDetail(time + ' 00:00:00', time + ' 23:59:59');
      },
      getTicketApi() {
        this.$store.commit('setLoading', true);
        let postData = {};
        postData = {
          page: this.pageData.CurrentPage,
          pagesize: this.pageData.PageSize,
        };
        this.$store
          .dispatch('History/getTicketApi', {
            isset: false,
            ...postData,
          })
          .then((res) => {
            this.betHistoryData = res.data.list;
            this.pageData.CurrentPage = res.data.CurrentPage;
            this.pageData.TotalPage = res.data.TotalPage;
            this.pageData.PageSize = res.data.PageSize;
            this.pageData.Count = res.data.Count;
            this.$nextTick(() => {
              this.$refs.Record_main.scrollTop = 0;
            });
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      getTicketStatsApi() {
        this.$store
          .dispatch('History/getTicketStatsApi', {
            isset: true,
          })
          .then((res) => {
            this.weekData = res.data;
            this.$forceUpdate();
          });
      },
      getBetHistoryDetail(startTime, endTime) {
        const postData = {
          starttime: startTime,
          endtime: endTime,
          isset: true,
          normal: false,
        };
        this.todayDetails.length = 0;
        this.todayDetails = [];

        this.$store.dispatch('History/getTicketStatsApi', postData).then((res) => {
          this.todayDetails = res.data
            .map((it) => {
              return {
                Amounts: it.amount,
                ResultAmounts: it.ResultAmount,
                active: false,
                canwins: it.ToWin,
                CatName: it.CatName,
                data: it.Items.map((itemData) => {
                  itemData.dataBet = itemData.dataBet.map((dataBet) => {
                    if (dataBet.WagerPosName === this.$t('Common.HomeTeam')) {
                      dataBet.betname = dataBet.HomeTeam;
                    } else if (dataBet.WagerPosName === this.$t('Common.AwayTeam')) {
                      dataBet.betname = dataBet.AwayTeam;
                    } else {
                      dataBet.betname = dataBet.WagerPosName;
                    }
                    return dataBet;
                  });
                  return itemData;
                }),
              };
            })
            .filter((it, index) => index !== res.data.length - 1);
        });
      },
    },
    async created() {
      this.getTicketApi(false);
      this.getTicketStatsApi();
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/theme/mixin.scss';
  @import '../../assets/sass/global.scss';
  #app[data-theme^='light'] {
    #HistoryRecord {
    }
  }

  #app[data-theme^='dark'] {
    #HistoryRecord {
      background: #585858;
    }
  }
  #HistoryRecord {
    & * {
      user-select: text !important;
    }
    height: 100%;
    .whiteColor {
      color: white !important;
    }
    .ScoreColor {
      color: red !important;
      margin-right: 1px;
    }
    .linkColor {
      color: #409eff !important;
    }
    .betTeamColor {
      color: #0077ff;
    }
    .oddColor {
      color: #f00;
    }
    .Remarks {
      color: #f00;
    }
    .DeleteBG {
      background-color: #d5d5d5;
    }
    .textLine > div > ul > li,
    .textLine > ul > li {
      text-decoration: line-through;
    }
    .cancel {
      text-decoration: line-through;
    }
    .betSumTotal {
      color: #ffe900 !important;
    }
    .resultScore {
      color: #0a9c00 !important;
    }
    .startGameTime {
      color: #666;
    }
    .HomeTeamSign {
      color: #ff8800;
    }
    .Record_head {
      width: 100%;
      height: 70px;
      // position: fixed;
      top: 0;
      // background-color: #3fa381;
      @include base_background();
      h3 {
        color: #fff; //#81f0ca;
        font-size: 21px;
        display: inline-block;
        margin-left: 20px;
      }
    }
    .Record_top {
      background-color: #404040;
      color: #bbb;
      width: 100%;
      // position: fixed;
      top: 70px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      font-size: 13px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      ul {
        display: flex;
        li {
          margin-right: 20px;
          cursor: pointer;
        }
        li.active {
          color: #ffe900;
        }
      }
    }
    .Record_main {
      height: calc(100% - 70px - 40px);
      overflow: auto;
      .Record_mainContainer {
        height: fit-content;
        .footerPageBlock {
          display: flex;
          justify-content: center;
          padding: 15px 0;
          background: #e5e5e5;
        }
        .rt_info {
          color: #666;
        }
        .NoData {
          margin: 20px auto;
          text-align: center;
          font-size: 14px;
          width: 100%;
        }
        table {
          width: 100%;
          font-size: 13px;
          border: none;
          background-color: #e5e5e5;
          th {
            background: #d8d8d8;
            font-size: 14px;
            //border-bottom: 1px solid #bbb;
            height: 48px;
          }
          td {
            border-bottom: 1px solid #bbb;
            border-right: 1px solid #bbb;
            padding: 10px;
            ul {
              li {
                line-height: 20px;
              }
            }
          }

          td.rt_fs {
            padding: 0;
            .rt_fs_list {
              display: flex;
              align-items: center;
              min-height: 100px;
              border-bottom: 1px solid #bbb;
              > div {
                min-height: 100px;
                width: 50px;
                text-align: center;
                line-height: 100px;
                border-right: 1px solid #bbb;
              }
              > ul {
                padding: 0 20px;
              }
            }
            .rt_fs_list:last-child {
              border: none;
            }
          }
          td.rt_betval {
            text-align: right;
            font-weight: bold;
            font-size: 17px;
          }
          .rt_foot {
            text-align: right;
            // position: fixed;
            bottom: 0;
            width: 100%;
            td {
              background-color: #404040;
              color: #ffffff;
              font-size: 14px;
              flex: 1;
            }
          }
        }
      }
    }
    .weektable {
      tr {
        td {
          text-align: right;
        }
        td:first-child {
          text-align: left;
          padding: 0 20px;
        }
      }
      .week {
        td {
          background: #404040;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        td:nth-child(2) {
          color: #ffe900;
        }
        td:nth-child(3) {
          color: #20b616;
        }
        td:nth-child(4) {
          color: #20b616;
        }
      }
    }
    .collapsetable {
      tr {
        td {
          background: #ccc;
          text-align: right;
          font-weight: bold;
          font-size: 17px;
        }
        td:first-child {
          cursor: pointer;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
</style>
