<template>
  <div id="GamesMenu" :class="{ collapse: isCollapse }">
    <div class="my-type-header">
      <template v-if="isCollapse">
        <i class="el-icon-arrow-right my-arrow-icon" @click="toggleCollaspe()" />
      </template>
      <template v-else>
        <div class="my-type-list">
          <div
            v-for="typeItem in gameTypeList"
            :key="typeItem.key"
            class="my-type-item"
            :class="{ active: typeItem.key === selectGameType }"
            @click="onSelectType(typeItem)"
          >
            {{ typeItem.value }}
          </div>
        </div>
        <i class="el-icon-arrow-left my-arrow-icon" @click="toggleCollaspe()" />
      </template>
    </div>
    <div
      v-if="isCollapse"
      class="my-select-type-item"
      @mouseenter="showTypeMenu($event)"
      @mouseleave="onLeaveItem()"
    >
      <div class="my-type-name">
        {{ selectGameTypeName }}
      </div>
    </div>
    <div v-for="catItem in catList" :key="catItem.catid">
      <div
        v-if="catItem.count"
        class="my-cat-item"
        :class="{ active: catItem.catid === selectCatID }"
        @click="onSelectCat(catItem)"
        @mouseenter="showWagerMenu(catItem, $event)"
        @mouseleave="onLeaveItem()"
      >
        <template v-if="catItem.catid === championWagerKey">
          <div v-if="catItem.catid === championWagerKey" class="champion-icon">
            <img class="champion-img" src="@/assets/img/champion.png" />
          </div>
        </template>
        <template v-else>
          <img
            class="my-cat-icon"
            :class="{ fix88888: catItem.catid === 888888 }"
            :src="getCatIcon(catItem)"
          />
        </template>
        <div class="my-cat-name">
          {{ catItem.catName }}
        </div>
        <div class="my-cat-count">{{ catItem.count }}</div>
      </div>
      <div
        v-if="!isCollapse && catItem.catid === selectCatID && catItem.Items?.length"
        class="my-wager-list"
      >
        <div
          v-for="wagerItem in catItem.Items"
          :key="`${catItem.catid}-${wagerItem.WagerTypeKey}`"
          class="my-wager-item"
          :class="{ active: wagerItem.WagerTypeKey === selectWagerTypeKey }"
          @click="onSelectWager(catItem, wagerItem.WagerTypeKey)"
        >
          <div class="my-wager-name">
            {{ wagerItem.WagerTypeName }}
          </div>
          <div class="my-wager-count">
            {{ wagerItem.count }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isShowMenu"
      id="app-menu"
      class="app-menu"
      tabindex="-1"
      @mouseenter="onEnterMenu()"
      @mouseleave="onLeaveMenu()"
    />
  </div>
</template>

<script>
  import { favoriteCatID, championWagerKey } from '@/config/index.js';
  import { getMenuIconByCatID } from '@/utils/SportLib';

  const SESSION_GameType = 'GameType';
  const SESSION_CatID = 'CatID';
  const SESSION_WagerTypeKey = 'WagerTypeKey';

  export default {
    data() {
      return {
        isCollapse: false,
        isShowMenu: false,
        intervalID1: null,
        intervalID2: null,
        championWagerKey,
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      gameTypeList() {
        return this.gameStore.GameTypeList;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectGameTypeName() {
        const { selectGameType } = this;
        const gameType = this.gameTypeList.find((typeItem) => typeItem.key === selectGameType);
        return gameType ? gameType.value : '';
      },
      catList() {
        return [
          {
            catid: favoriteCatID,
            catName: this.$t('Common.Collect'),
            count: this.$store.state.Setting.UserSetting.favorites.length,
          },
          ...this.$store.getters['Game/selectMenuCatList'],
        ];
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      tableSort() {
        return this.$store.state.Setting.UserSetting.tableSort;
      },
      isCallGameDetailAPI() {
        return this.gameStore.isCallGameDetailAPI;
      },
    },
    watch: {
      tableSort: {
        handler() {
          this.updateGameDetail({ loading: true, clear: true });
        },
      },
      isCallGameDetailAPI: {
        handler() {
          this.updateGameDetail({ loading: false, clear: false });
        },
      },
    },
    methods: {
      storeToSession() {
        sessionStorage.setItem(SESSION_GameType, this.selectGameType);
        sessionStorage.setItem(SESSION_CatID, this.selectCatID);
        sessionStorage.setItem(SESSION_WagerTypeKey, this.selectWagerTypeKey);
      },
      restoreFromSession() {
        const selectGameType = +sessionStorage.getItem(SESSION_GameType);
        const selectCatID = +sessionStorage.getItem(SESSION_CatID);
        let wagerKey = +sessionStorage.getItem(SESSION_WagerTypeKey);
        if (!wagerKey) {
          wagerKey = null;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID,
          selectWagerTypeKey: wagerKey,
        });
      },
      updateGameDetail({ loading = false, clear = false }) {
        if (loading) {
          this.$store.commit('setLoading', true);
        }
        return this.$store.dispatch('Game/updateGameDetail', { clear }).finally(() => {
          if (loading) {
            this.$store.commit('setLoading', false);
          }
        });
      },
      updateGameDetailSmall() {
        return this.$store.dispatch('Game/updateGameDetailSmall');
      },
      getCatIcon(catItem) {
        const { catid: catID } = catItem;
        const icon = getMenuIconByCatID(catID);
        if (icon) {
          return require('@/assets/img/common/menuIcon/' + icon);
        } else {
          return '';
        }
      },
      toggleCollaspe() {
        this.isCollapse = !this.isCollapse;
      },
      showTypeMenu(event) {
        if (!this.isCollapse) return;
        const menu = document.getElementById('app-menu');
        if (!menu) return;
        menu.textContent = '';

        const { gameTypeList, selectGameType } = this;
        const fragment = new DocumentFragment();
        const listDiv = document.createElement('div');
        listDiv.classList = 'app-type-list';
        for (const typeItem of gameTypeList) {
          const { key: typeKey } = typeItem;
          const itemDiv = document.createElement('div');
          itemDiv.classList = 'app-type-item';
          if (typeKey === selectGameType) {
            itemDiv.classList.add('active');
          }
          itemDiv.onclick = () => {
            this.onSelectType(typeItem);
            this.isShowMenu = false;
          };
          const nameDiv = document.createElement('div');
          nameDiv.classList = 'app-type-name';
          nameDiv.textContent = typeItem.value;
          itemDiv.append(nameDiv);
          listDiv.append(itemDiv);
        }
        fragment.append(listDiv);
        menu.append(fragment);

        const style = menu.style;
        const target = event.target;
        style.top = target.offsetTop - 16 + 'px';
        style.left = target.offsetLeft + target.offsetWidth - 2 + 'px';

        this.isShowMenu = true;
        this.$nextTick(() => {
          menu.focus();
        });
      },
      showWagerMenu(catItem, event) {
        if (!this.isCollapse) return;
        const menu = document.getElementById('app-menu');
        if (!menu) return;
        menu.textContent = '';

        const { Items: wagerList } = catItem;
        if (!wagerList) {
          this.isShowMenu = false;
          return;
        }

        const { selectWagerTypeKey } = this;
        const fragment = new DocumentFragment();
        const listDiv = document.createElement('div');
        listDiv.classList = 'app-wager-list';
        for (const wagerItem of wagerList) {
          const { WagerTypeKey: wagerKey } = wagerItem;
          const itemDiv = document.createElement('div');
          itemDiv.classList = 'app-wager-item';
          if (wagerKey === selectWagerTypeKey) {
            itemDiv.classList.add('active');
          }
          itemDiv.onclick = () => {
            this.onSelectWager(catItem, wagerKey);
            this.isShowMenu = false;
          };
          const nameDiv = document.createElement('div');
          nameDiv.classList = 'app-wager-name';
          nameDiv.textContent = wagerItem.WagerTypeName;
          const countDiv = document.createElement('div');
          countDiv.classList = 'app-wager-count';
          countDiv.textContent = wagerItem.count;
          itemDiv.append(nameDiv);
          itemDiv.append(countDiv);
          listDiv.append(itemDiv);
        }
        fragment.append(listDiv);
        menu.append(fragment);

        const target = event.target;
        const style = menu.style;
        style.top = target.offsetTop - 8 * (wagerList.length - 1) + 'px';
        style.left = target.offsetLeft + target.offsetWidth - 2 + 'px';

        this.isShowMenu = true;
        this.$nextTick(() => {
          menu.focus();
        });
      },
      onEnterMenu() {
        this.isShowMenu = true;
      },
      onLeaveMenu() {
        this.isShowMenu = false;
      },
      onLeaveItem() {
        this.isShowMenu = false;
      },
      onSelectType(typeItem) {
        const { key: typeKey } = typeItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        if (typeKey === selectGameType) {
          return;
        }

        let catID = null;
        let wagerKey = null;
        if ([favoriteCatID].includes(selectCatID)) {
          catID = selectCatID;
        } else {
          const menuData = this.gameStore.FullMenuList.find(
            (menuData) => menuData.GameType === typeKey
          );
          if (menuData) {
            const { item: catList } = menuData.LeftMenu;
            if (catList?.length) {
              const catItem = catList.find((catItem) => catItem.catid === selectCatID);
              if (catItem) {
                catID = selectCatID;
                const { Items: wagerList } = catItem;
                if (wagerList?.length) {
                  const wagerItem = wagerList.find(
                    (wagerItem) => wagerItem.WagerTypeKey === selectWagerTypeKey
                  );
                  if (wagerItem) {
                    wagerKey = wagerItem.WagerTypeKey;
                  } else {
                    wagerKey = wagerList[0].WagerTypeKey;
                  }
                }
              } else {
                const catData0 = catList[0];
                catID = catData0.catid;
                const { Items: wagerList } = catData0;
                if (wagerList?.length) {
                  wagerKey = wagerList[0].WagerTypeKey;
                }
              }
            }
          }
        }

        if (catID === null) {
          catID = 1;
          wagerKey = 1;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: typeKey,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.$emit('ChangeCat');
        this.updateGameDetail({ loading: true, clear: true }).then((res) => {
          this.$store.dispatch('Game/updateGameMenuList').then((res) => {});
        });
      },
      onSelectCat(catItem) {
        const { catid: catID, Items: wagerList } = catItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const wagerKey = wagerList?.length ? wagerList[0].WagerTypeKey : null;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) {
          return;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.$emit('ChangeCat');
        this.updateGameDetail({ loading: true, clear: true });
      },
      onSelectWager(catItem, wagerKey) {
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const { catid: catID } = catItem;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) {
          return;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        this.updateGameDetail({ loading: true, clear: true });
      },
    },
    mounted() {
      const gameType = sessionStorage.getItem(SESSION_GameType);
      const catID = sessionStorage.getItem(SESSION_CatID);
      if (gameType && catID) {
        this.restoreFromSession();
      }

      this.updateGameDetail({ loading: true, clear: true });

      this.intervalID1 = setInterval(() => {
        this.updateGameDetailSmall();
        this.$store.dispatch('updateUserBalance');
      }, 10000);

      this.intervalID2 = setInterval(() => {
        this.$store.dispatch('Game/updateGameMenuList', false);
      }, 15000);

      window.addEventListener('beforeunload', this.storeToSession);
    },
    beforeDestroy() {
      clearInterval(this.intervalID1);
      clearInterval(this.intervalID2);
      window.removeEventListener('beforeunload', this.storeToSession);
    },
  };
</script>

<style lang="scss">
  @import '@/assets/sass/theme/mixin.scss';
  @import '@/assets/sass/theme/themeVariable.scss';

  #app-menu {
    position: fixed;

    &:focus {
      outline: none;
    }
  }

  .app-type-list {
    background-color: #ddd;
    border-radius: 4px;
    box-shadow: -2px 0px 4px #aaa, 2px 2px 4px #aaa;
    overflow: hidden;
  }

  .app-type-item {
    display: flex;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
    cursor: pointer;

    &.active {
      background-color: #eee;

      .app-type-name {
        color: $nav_submenu_active_text;
      }
    }

    &:hover {
      background-color: #fff;
    }
  }

  .app-type-name {
    @include nav-TopTextcolor();
    flex-grow: 1;
    margin: 0 24px;
  }

  .app-wager-list {
    background-color: #ddd;
    border-radius: 4px;
    box-shadow: -2px 0px 4px #aaa, 2px 2px 4px #aaa;
    overflow: hidden;
  }

  .app-wager-item {
    display: flex;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
    cursor: pointer;

    &.active {
      background-color: #eee;

      .app-wager-name {
        color: $nav_submenu_active_text;
      }
    }

    &:hover {
      background-color: #fff;
    }
  }

  .app-wager-name {
    @include nav-TopTextcolor();
    flex-grow: 1;
    margin: 0 0 0 24px;
  }

  .app-wager-count {
    @include nav-TopTextNumbercolor();
    margin: 0 24px;
  }

  #app[data-theme^='dark'] {
    .app-type-list,
    .app-wager-list {
      box-shadow: -2px 0px 4px #333, 2px 2px 4px #333;
      background-color: $GamesNavMenu_BG_COLOR1;
    }

    .app-type-item,
    .app-wager-item {
      border-bottom: 1px solid #666;

      &.active {
        background-color: #222;
      }

      &:hover {
        background-color: #111;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  @import '@/assets/sass/theme/themeVariable.scss';

  #GamesMenu {
    width: 180px;
    background: $GamesNavMenu_BG_COLOR;

    &.collapse {
      width: 64px;

      .my-cat-name,
      .my-cat-count {
        display: none;
      }
    }
  }

  .champion {
    position: absolute;
  }

  .champion-img {
    position: relative;
    top: -3px;
    left: -12px;
    width: 40px;
    height: 40px;
    object-fit: contain;
    animation: myfirst 1s ease-in-out 0s infinite alternate;
  }

  @keyframes myfirst {
    100% {
      transform: scale(0.72);
    }
  }

  .my-type-header {
    @include nav-headrtBgcolor();
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    color: #ddd;
  }

  .my-arrow-icon {
    padding: 0 4px;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  .my-type-list {
    flex-grow: 1;
    display: flex;
  }

  .my-type-item {
    flex-grow: 1;
    margin: 0 4px;
    padding-bottom: 2px;
    border-bottom: 2px solid transparent;
    text-align: center;
    cursor: pointer;

    &.active {
      color: #ddd;
      border-bottom-color: #ddd;

      &:hover {
        border-bottom-color: #fff;
      }
    }

    &:hover {
      color: #fff;
    }
  }

  .my-select-type-item {
    @include base-background();
    height: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
  }

  .my-type-name {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding: 0 4px;
    border-bottom: 2px solid #ddd;
    color: #fff;

    &:hover {
      border-bottom-color: #fff;
    }
  }

  .my-cat-item {
    display: flex;
    justify-content: center;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
    cursor: pointer;

    &.active {
      background-color: #eee;

      .my-cat-name {
        color: $nav_submenu_active_text;
      }
    }

    &:hover {
      background-color: #fff;
    }
  }

  .champion-icon {
    width: 18px;
    margin: 0 16px;
  }

  .my-cat-icon {
    width: 18px;
    margin: 0 16px;

    &.fix88888 {
      width: 28px;
      height: 28px;
      margin: 3px 11px 0;
    }
  }

  .my-cat-name {
    @include nav-TopTextcolor();
    flex-grow: 1;
  }

  .my-cat-count {
    @include nav-TopTextNumbercolor();
    margin: 0 16px 0 4px;
  }

  .my-wager-list {
    background-color: #ddd;
  }

  .my-wager-item {
    display: flex;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
    cursor: pointer;

    &.active {
      background-color: #eee;

      .my-wager-name {
        color: $nav_submenu_active_text;
      }
    }

    &:hover {
      background-color: #fff;
    }
  }

  .my-wager-name {
    @include nav-TopTextcolor();
    flex-grow: 1;
    margin: 0 0 0 50px;
    white-space: nowrap;
  }

  .my-wager-count {
    @include nav-TopTextNumbercolor();
    margin: 0 16px 0 4px;
  }

  #app[data-theme^='dark'] {
    #GamesMenu {
      background: $GamesNavMenu_BG_COLOR1;
    }

    .my-cat-item {
      border-bottom: 1px solid #666;

      &.active {
        background-color: #222;
      }

      &:hover {
        background-color: #111;
      }
    }

    .my-wager-item {
      border-bottom: 1px solid #666;
      background-color: #444;

      &.active {
        background-color: #222;

        .my-wager-name {
          color: $nav_submenu_active_text;
        }
      }

      &:hover {
        background-color: #111;
      }
    }
  }
</style>
