<template>
  <div id="Themes">
    <table>
      <tr>
        <th class="th1">
          {{ $t('Common.ThemeMode') }}
        </th>
        <th>
          {{ $t('Common.ThemeModeColor') }}
        </th>
      </tr>
      <tr>
        <td class="td1">
          <div v-for="t in themeModes" :key="t" class="theme1">
            <img :src="require(`@/assets/theme/${t}.svg`)" height="24px" @click="setThemeMode(t)" />
            <div
              v-if="t === themeMode"
              class="theme1-current"
              style="
                position: absolute;
                bottom: 6px;
                right: 2px;
                color: green;
                pointer-events: none;
              "
              >V</div
            >
          </div>
        </td>
        <td class="td2">
          <div v-for="t in themeMap" :key="t.key" class="theme2-wrap">
            <div
              class="theme2"
              :class="{ 'theme2-current': t.key === themeKey }"
              :style="'background-color:' + t[themeMode]"
              @click="setThemeKey(t.key)"
            />
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import { themeModes, themeMap } from '@/config/theme';

  export default {
    data() {
      return {
        themeModes,
        themeMap,
      };
    },
    computed: {
      ...mapState(['themeMode', 'themeKey']),
      ...mapGetters(['themeInfo']),
    },
    methods: {
      ...mapMutations(['setThemeMode', 'setThemeKey']),
    },
  };
</script>

<style lang="scss" scoped>
  #Themes {
    table {
      width: 180px;
      text-align: center;
      padding: 8px 0;
    }
    th {
      padding: 4px;
    }
    td {
      padding: 4px;
    }
    .th1 {
      width: 64px;
    }
    .td1 {
      border-right: 1px solid #bbb;
    }
    .td2 {
    }
    .theme1 {
      position: relative;
      display: inline-block;
      margin-bottom: 2px;
      cursor: pointer;
    }
    .theme1-current {
      position: absolute;
      bottom: 6px;
      right: 2px;
      color: green;
      pointer-events: none;
    }
    .theme2-wrap {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
    .theme2 {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
      cursor: pointer;
    }
    .theme2-current {
      outline: 1px solid gray;
      outline-offset: 3px;
    }
  }
</style>
