<template>
  <div id="GamesHeader">
    <img class="logo" :src="siteLogo" />
    <ul class="links">
      <li @click="openLive">
        {{ $t('Common.LivePlay') }}
      </li>
      <li @click="openMovie">
        {{ $t('Common.Movie') }}
      </li>
      <li @click="openScore">
        {{ $t('GamesHeader.LiveScore') }}
      </li>
      <li @click="openResult">
        {{ $t('GamesHeader.GameResult') }}
      </li>
      <li @click="openHistory">
        {{ $t('GamesHeader.BetList') }}
      </li>
      <li class="news" @click="openNews">
        {{ $t('GamesHeader.news') }}
      </li>
      <!--
      <li @click="openDGLive">
        {{ $t('Common.DGLive') }}
      </li>
      -->
    </ul>
    <div class="promotion">
      <Transition name="promotion">
        <img class="promotion-image" :src="promotionImage" />
      </Transition>
    </div>
    <ul class="user">
      <li>
        {{ userAccount }}
      </li>
      <li v-if="userBalance || userBalance === 0">
        {{ `$${toCurrency(userBalance)}` }}
      </li>
      <li v-if="isCreditSite">
        <img
          class="user-icon"
          src="@/assets/img/common/icon_header_service.svg"
          :title="$t('GamesHeader.ContactService')"
          @click="openService"
        />
        <div v-show="userUnread > 0" class="unread" @click="openService">
          {{ userUnread }}
        </div>
      </li>
      <li>
        <img
          class="user-icon"
          src="@/assets/img/common/icon_header_user.svg"
          :title="$t('GamesHeader.PersonalSetting')"
          @click="showPanel"
        />
        <div v-if="isPanelShow" class="panel">
          <div class="panel-bg" @click="hidePanel" />
          <ul class="panel-menu" :class="{ casino: isCasinoSite }">
            <li @click="openSetting">
              <img class="panel-icon" src="@/assets/img/pc/setting.svg" />
              <div class="panel-text">
                {{ $t('GamesBetInfo.Setting') }}
              </div>
            </li>
            <li @click="openUser">
              <img class="panel-icon" src="@/assets/img/pc/member.svg" />
              <div class="panel-text">
                {{ $t('Common.Profile') }}
              </div>
            </li>
            <li v-if="isCreditSite" class="panel-item" @click="openPassword">
              <img class="panel-icon" src="@/assets/img/pc/password.svg" />
              <div class="panel-text">
                {{ $t('Common.ChangePassword') }}
              </div>
            </li>
            <li @click="openLimit">
              <img class="panel-icon" src="@/assets/img/pc/member.svg" />
              <div class="panel-text">
                {{ $t('Common.Limit') }}
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li v-if="isCreditSite">
        <img
          class="user-icon"
          src="@/assets/img/common/logout.svg"
          :title="$t('GamesHeader.Logout')"
          @click="tryLogout"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import { getSportLiveApi, getMovieTheaterApi } from '@/api/sport';
  import { getDGLiveLoginApi } from '@/api/third';
  import { toCurrency } from '@/config/converter';

  export default {
    data() {
      return {
        isPanelShow: false,
        promotionImageIndex: 1,
        promotionInteral: null,
      };
    },
    computed: {
      ...mapState(['userBalance', 'userUnread', 'i18nLocale']),
      ...mapGetters(['isCasinoSite', 'isCreditSite', 'siteLogo', 'userAccount']),
      promotionImage() {
        return `/promotion/${this.promotionImageIndex}.png`;
      },
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading']),
      ...mapActions(['logout']),
      toCurrency,
      openLive() {
        this.showLoading();
        getSportLiveApi()
          .then((response) => {
            const url = response.data;
            if (url) window.open(url, 'live');
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.hideLoading();
          });
      },
      openMovie() {
        this.showLoading();
        getMovieTheaterApi()
          .then((response) => {
            const url = response.data;
            if (url) window.open(url, 'live');
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.hideLoading();
          });
      },
      openDGLive() {
        this.showLoading();
        getDGLiveLoginApi()
          .then((response) => {
            const url = response.data?.GameUrl;
            if (url) window.open(url, 'dglive');
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.hideLoading();
          });
      },
      openScore() {
        const url = `score/${this.i18nLocale}`;
        window.open(url, 'score');
      },
      openResult() {
        const { href: url } = this.$router.resolve({
          path: 'GameResult',
        });
        if (url) window.open(url, 'result');
      },
      openHistory() {
        const { href: url } = this.$router.resolve({
          path: 'HistoryRecord',
        });
        if (url) window.open(url, 'history');
      },
      openNews() {
        const { href: url } = this.$router.resolve({
          path: 'News',
        });
        if (url) window.open(url, 'news');
      },
      openLimit() {
        const { href: url } = this.$router.resolve({
          path: 'Limits',
        });
        if (url) window.open(url, 'limits');
      },
      openService() {
        this.$emit('openService');
        this.hidePanel();
      },
      openSetting() {
        this.$emit('openSetting');
        this.hidePanel();
      },
      openUser() {
        this.$emit('openPersonal', 1);
        this.hidePanel();
      },
      openPassword() {
        this.$emit('openPersonal', 2);
        this.hidePanel();
      },
      showPanel() {
        this.isPanelShow = true;
      },
      hidePanel() {
        this.isPanelShow = false;
      },
      tryLogout() {
        this.showLoading();
        this.logout()
          .then(() => {
            this.hideLoading();
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.promotionInteral = setInterval(() => {
        if (this.promotionImageIndex === 3) {
          this.promotionImageIndex = 1;
        } else {
          this.promotionImageIndex += 1;
        }
      }, 5000);
    },
    beforeDestroy() {
      if (this.promotionInteral) {
        clearInterval(this.promotionInteral);
        this.promotionInteral = null;
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #GamesHeader {
    @include base-background();

    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #aaa;
  }

  img.logo {
    width: 300px;
    height: auto;
    object-fit: contain;
  }

  ul.links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 8px;
    margin: 16px 36px 0 0;

    li {
      position: relative;
      padding: 4px;
      color: #eee;
      font-size: 15px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: #fff;
      }

      &.news {
        &::after {
          position: absolute;

          right: -32px;
          top: -16px;
          padding: 3px 5px;
          border-radius: 4px;
          background-color: #f00;
          line-height: 13px;
          font-size: 13px;
          animation: news 1s ease-in-out 0s infinite alternate;
          content: 'News';
        }
      }
    }
  }

  @keyframes news {
    100% {
      transform: scale(0.8);
    }
  }

  ul.user {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 16px 0;

    li {
      position: relative;
      padding: 4px;
      color: #fff;
      line-height: 20px;

      img.user-icon {
        width: 20px;
        height: 20px;
        color: white;
        cursor: pointer;
      }

      .unread {
        position: absolute;
        right: -6px;
        top: -6px;
        z-index: 1;
        padding: 3px 6px;
        border-radius: 10px;
        background-color: #c84141;
        color: #fff;
        line-height: 13px;
        font-size: 13px;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    .panel {
      position: absolute;
    }

    .panel-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.2);
    }

    ul.panel-menu {
      position: absolute;
      width: 162px;
      left: 0;
      bottom: 0;
      z-index: 100;
      transform: translate(-56%, 100%);
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 4px 8px;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 0 8px 0px #000;
      background: #fff;

      &.casino {
        transform: translate(-72%, 100%);
      }

      li {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        img.panel-icon {
          width: auto;
          height: 28px;
        }

        .panel-text {
          color: #222;
          white-space: nowrap;
        }
      }
    }
  }

  .promotion {
    flex: 1 1 360px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-self: center;

    img.promotion-image {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .promotion-enter-active {
    transition: all 1s ease-out;
  }

  .promotion-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .promotion-enter-from,
  .promotion-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>
