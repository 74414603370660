<template>
  <div id="GamesSetup">
    <div class="setUp_L">
      <span class="timeBlockContainer">
        <div class="dayBlock">
          {{ TimeCountDay }}
        </div>
        <div class="timeBlock" style="width: 48px">
          {{ TimeCountDownTime }}
        </div>
      </span>
      <div style="position: relative">
        <img class="themes-icon" src="@/assets/theme/colors.jpg" @click="toggleThemes()" />
        <div v-if="isShowThemes" class="themes-bg" @click="toggleThemes()" />
        <Themes v-if="isShowThemes" class="themes" />
      </div>
    </div>
    <div class="setUp_C">
      <div class="marquee-wrapper" @click="clickMarquee">
        <div class="icon" />
        <Marquee ref="Marquee" :ok="ok" />
      </div>
      <div v-if="ok" class="options-wrapper">
        <span>
          {{ $t('GamesSetup.AcceptBetter') }}
          <el-checkbox v-model="isAcceptBetter" @change="checkboxChangeHandler" />
        </span>
        <el-divider direction="vertical" v-if="!isFavoritePage" />
        <span class="selectLeague" @click="showLeagueSelectDialog" v-if="!isFavoritePage">
          {{ $t('GamesSetup.LeagueSelect') }}
        </span>
        <el-divider direction="vertical" />
        <el-dropdown @command="handleIsIncludePrincipal" trigger="click" class="dropDown">
          <span class="el-dropdown-link">
            {{ principalOption.find((it) => it.value === isIncludePrincipal).label }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, key) in principalOption"
              :key="key"
              :command="item.value"
              :class="item.value === isIncludePrincipal ? 'DefaultFocus' : ''"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-divider direction="vertical" v-if="!isFavoritePage" />
        <el-dropdown @command="handleSort" trigger="click" class="dropDown" v-if="!isFavoritePage">
          <span class="el-dropdown-link">
            {{ sortList.find((it) => it.value === sortValue).label }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, key) in sortList"
              :key="key"
              :command="item.value"
              :class="item.value === sortValue ? 'DefaultFocus' : ''"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-divider direction="vertical" class="margin-0" />
      </div>
    </div>
    <div class="setUp_R">
      <span class="setUp_color">
        {{ $t('GamesSetup.QuickBet') }}
      </span>
      <input
        v-show="quickBetEnable"
        v-model.number="quickBetAmount"
        class="setUp_input"
        type="number"
        :placeholder="$t('GamesSetup.PlzInputAmount')"
        @blur="onBlurAmount"
      />
      <el-switch
        v-model="quickBetEnable"
        active-color="#13ce66"
        inactive-color="#ff4949"
        @change="quickBetEnableChangeHandler"
      />
      <span style="padding: 0 8px; cursor: pointer" @click="openRule">
        {{ $t('GamesHeader.GameRule') }}
      </span>
      <el-popover
        class="popover"
        popper-class="fastBet-help-popper"
        placement="bottom-start"
        trigger="hover"
      >
        <div class="help-title">
          {{ $t('GamesSetup.QuickBetInfo') }}
        </div>
        <div class="help-detail">
          {{ $t('GamesSetup.QuickBetInfo1') }}
          <br />
          {{ $t('GamesSetup.QuickBetInfo2') }}
          <br />
          {{ $t('GamesSetup.QuickBetInfo3') }}
        </div>
        <i class="el-icon-question" slot="reference" />
      </el-popover>
      <div class="cartBtn" @click="cartBtnClick">
        <i :class="isShowGameBet ? 'el-icon-arrow-right' : 'el-icon-shopping-cart-2'" />
      </div>
    </div>
    <el-dialog
      class="LeagueSelectDialog"
      :title="$t('GamesSetup.LeagueSelect')"
      width="80%"
      center
      :visible.sync="isShowLeagueSelectDialog"
    >
      <div v-if="ok" class="LeagueSelectDialogContent">
        <div class="ContentHeader">
          <el-checkbox size="medium" v-model="dialogData.selectAll" @change="dialogSelectAll">
            {{ $t('GamesSetup.SelectAll') }}
          </el-checkbox>
          <el-checkbox size="medium" v-model="dialogData.onlyShowCheck">
            {{ $t('GamesSetup.SelectCheck') }}
          </el-checkbox>
        </div>
        <div class="leagueItemContentList">
          <div
            v-for="(leagueData, index) in LeagueListDataFilter"
            :key="index"
            class="leagueItem"
            :title="leagueData.LeagueName"
          >
            <el-checkbox size="medium" v-model="leagueData.isSelect">
              {{ leagueData.LeagueName }}
            </el-checkbox>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="cancelBtn" @click="isShowLeagueSelectDialog = false">
          {{ $t('GamesSetup.Cancel') }}
        </div>
        <div class="submitBtn" type="primary" @click="leagueChooseSelectHandler">
          {{ $t('GamesSetup.Submit') }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { getSiteTimeApi } from '@/api';
  import { favoriteCatID, hotCatID } from '@/config';
  import Themes from './Themes.vue';
  import Marquee from './Marquee.vue';

  export default {
    props: {
      ok: { type: Boolean, value: false },
      isShowGameBet: {
        type: Boolean,
      },
    },
    components: {
      Themes,
      Marquee,
    },
    data() {
      return {
        quickBetEnable: false,
        listValue: this.$t('GamesSetup.IncludePrincipal'),
        isAcceptBetter: null,
        sortValue: null,
        sortList: [
          { value: 1, label: this.$t('GamesSetup.TimeSort') },
          { value: 0, label: this.$t('GamesSetup.HotSort') },
        ],
        isIncludePrincipal: null,
        principalOption: [
          { value: false, label: this.$t('GamesSetup.NotIncludePrincipal') },
          { value: true, label: this.$t('GamesSetup.IncludePrincipal') },
        ],
        currentTime: null,
        countInterval: null,
        announcementInterval: null,
        isShowLeagueSelectDialog: false,
        dialogData: {
          selectAll: true,
          onlyShowCheck: false,
        },
        LeagueListData: [],
        isShowThemes: false,
      };
    },
    computed: {
      ...mapState(['userBalance', 'i18nLocale']),
      ...mapGetters(['themeInfo']),
      quickBetAmount: {
        get() {
          return this.isQuickBet.amount;
        },
        set(value) {
          if (Number.isInteger(value) && value > 0) {
            this.$store.commit('Game/setQuickBetAmount', value);
          }
        },
      },
      TimeCountDown() {
        return this.$lib.timeFormatWithOutYY(this.currentTime);
      },
      TimeCountDay() {
        return this.TimeCountDown?.day;
      },
      TimeCountDownTime() {
        return this.TimeCountDown?.time;
      },
      tableSort() {
        return this.$store.state.Setting.UserSetting.tableSort;
      },
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
      acceptBetter() {
        return this.$store.state.Setting.UserSetting.acceptBetter;
      },
      LeagueListDataFilter() {
        if (this.dialogData.onlyShowCheck) {
          return this.LeagueListData.filter((it) => it.isSelect);
        } else {
          return this.LeagueListData;
        }
      },
      isQuickBet() {
        return this.$store.state.Game.isQuickBet;
      },
      selectCatID() {
        return this.$store.state.Game.selectCatID;
      },
      isFavoritePage() {
        const { selectCatID } = this;
        return selectCatID === favoriteCatID || selectCatID === hotCatID;
      },
      IconSrcJudge() {
        let lightName = '';
        if (this.themeInfo.includes('light')) {
          lightName = 'sun1';
        } else if (this.themeInfo.includes('dark')) {
          lightName = 'sun';
        }
        return require(`@/assets/img/pc/${lightName}.svg`);
      },
    },
    watch: {
      TimeCountDown() {
        const dateTime = this.TimeCountDown.time;
        if (dateTime === '12:00:10') {
          location.reload();
        }
      },
      tableSort: {
        handler() {
          this.sortValue = this.tableSort;
        },
        immediate: true,
      },
      includePrincipal: {
        handler() {
          this.isIncludePrincipal = this.includePrincipal;
        },
        immediate: true,
      },
      acceptBetter: {
        handler() {
          this.isAcceptBetter = this.acceptBetter;
        },
        immediate: true,
      },
    },
    methods: {
      clickMarquee() {
        const Ann = this.$router.resolve({
          path: 'Ann',
        });
        window.open(Ann.href, 'ann');
      },
      openRule() {
        const path = `rules/${this.i18nLocale}`;
        window.open(path, 'rule');
      },
      onBlurAmount() {
        if (!this.quickBetAmount) {
          this.quickBetAmount = this.$store.state.Setting.UserSetting.defaultAmount.amount;
        }
      },
      updateSelectAllData() {
        const selectItemLength = this.LeagueListData.filter((it) => it.isSelect === false).length;
        if (selectItemLength === 0) {
          this.dialogData.selectAll = true;
        } else {
          this.dialogData.selectAll = false;
        }
      },
      quickBetAmountChangeHandler() {
        this.quickBetAmount = parseFloat(this.quickBetAmount.toString().replace(/[^\d]/g, ''));
        if (isNaN(this.quickBetAmount)) {
          this.quickBetAmount = 0;
        }
        if (this.quickBetAmount > this.userBalance) {
          this.quickBetAmount = this.userBalance;
        }
        this.$store.commit('Game/setQuickBetAmount', this.quickBetAmount);
      },
      quickBetEnableChangeHandler() {
        if (this.quickBetAmount !== this.isQuickBet.amount) {
          this.quickBetAmount = this.isQuickBet.amount;
        }
        this.$store.commit('Game/setQuickBetEnable', this.quickBetEnable);
      },
      visibilitychangeEvent() {
        if (document.visibilityState === 'visible') {
          this.initTimeAPI();
        }
      },
      clearLeagueList() {
        this.isShowLeagueSelectDialog = false;
        this.dialogData = {
          selectAll: false,
          onlyShowCheck: false,
        };
        this.LeagueListData = [];
      },
      initTimeAPI() {
        getSiteTimeApi().then((res) => {
          this.currentTime = new Date(res.data.replace(/-/g, '/')).getTime();
        });
      },
      closeProject(value) {
        this.listValue = value.label;
      },
      handleCommand(command) {
        this.listValue = command;
      },
      handleSort(val) {
        this.$store.commit('Setting/setTableSort', val);
      },
      handleIsIncludePrincipal(val) {
        this.$store.commit('Setting/setIncludePrincipal', val);
      },
      checkboxChangeHandler(val) {
        this.$store.commit('Setting/setAcceptBetter', val);
      },
      showLeagueSelectDialog() {
        this.LeagueListData = this.$store.getters['Game/LeagueList'];
        this.isShowLeagueSelectDialog = true;
        this.updateSelectAllData();
      },
      dialogSelectAll(newVal) {
        this.LeagueListData.forEach((it) => (it.isSelect = newVal));
      },
      leagueChooseSelectHandler() {
        if (this.LeagueListData.filter((it) => it.isSelect).length === 0) {
          return;
        }
        const leagues = this.LeagueListData.filter((it) => it.isSelect).map((it) => it.LeagueID);
        this.$store.commit('Game/setSelectLeagueIDs', leagues);
        const cat = this.$store.state.Game.selectCatID;
        this.$store.commit('Setting/setLeagues', { cat, leagues: this.LeagueListData });
        this.isShowLeagueSelectDialog = false;
      },
      getAnnouncementApi() {
        this.$store.dispatch('Game/GetAnnouncement').then((res) => {
          if (res.data && res.data.length) {
            const marqueeList = res.data.map((it) => it.content);
            this.$refs.Marquee.pushStack(marqueeList);
          }
        });
      },
      cartBtnClick() {
        this.$emit('ClickShowGameBet', !this.isShowGameBet);
      },
      toggleThemes() {
        this.isShowThemes = !this.isShowThemes;
      },
    },
    mounted() {
      this.countInterval = setInterval(() => {
        if (this.currentTime !== null) {
          this.currentTime += 1000;
        }
      }, 1000);
      this.initTimeAPI();
      document.addEventListener('visibilitychange', this.visibilitychangeEvent);

      // 輪詢 公告 API
      this.announcementInterval = setInterval(() => {
        if (this.ok) {
          this.getAnnouncementApi();
        }
      }, 60000);
      setTimeout(() => {
        if (this.ok) {
          this.getAnnouncementApi();
        }
      }, 3000);
    },
    beforeDestroy() {
      document.removeEventListener('visibilitychange', this.visibilitychangeEvent);
      clearInterval(this.countInterval);
      clearInterval(this.announcementInterval);
    },
  };
</script>

<style lang="scss">
  @import '@/assets/sass/theme/mixin.scss';

  #GamesSetup {
    flex: 0 0 38px;
    height: 38px;
    width: 100%;
    @include background-color-hederTop();

    .LeagueSelectDialog {
      .el-dialog {
        max-width: 980px;
        .el-dialog__header {
          // background-color: #3fa381;
          @include base-background();
          padding: 8px;
          .el-dialog__title {
            color: white !important;
            font-size: 15px;
          }
          .el-dialog__headerbtn {
            right: 12px;
            top: 10px;
            .el-dialog__close {
              font-size: 20px;
              color: white;
            }
          }
        }
        .el-dialog__body {
          min-height: 300px;
          .el-checkbox {
            display: flex;
            align-items: center;
            font-size: 19px;

            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #606266;
            }
          }
          .ContentHeader {
            margin-bottom: 25px;
          }
        }
        .el-dialog__body,
        .el-dialog__footer {
          background-color: #eee;
        }
        .dialog-footer {
          display: flex;
          justify-content: center;
          .cancelBtn,
          .submitBtn {
            width: 160px;
            line-height: 40px;
            height: 40px;
            font-size: 15px;
            text-align: center;
            cursor: pointer;
          }
          .submitBtn {
            background-color: #2f8be6;
            &:hover {
              background-color: #3a9dff;
            }
          }
          .cancelBtn {
            background-color: #929292;
            margin-right: 10px;
            &:hover {
              background-color: #aaa;
            }
          }
        }
        .LeagueSelectDialogContent {
          .ContentHeader {
            display: flex;
            justify-content: flex-end;
          }
          .leagueItemContentList {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .leagueItem {
              width: 32%;
              min-width: 200px;
              margin: 0 2px;
              border: 1px solid #bebebe;
              padding: 5px;
              margin-bottom: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  .setUp_color {
    @include base-fontColor();
    margin-right: 4px;
    text-align: center;
  }

  #GamesSetup {
    color: #fff;
    height: 38px;
    font-size: 13px;
    @include background-color-hederTop();
    width: 100%;
    display: flex;
    .el-divider {
      height: 22px;
      background-color: #bbb; // #81f0ca;
      margin: 0 15px;
    }
    .setUp_L {
      flex-shrink: 0;
      width: 180px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      > div {
        height: 100%;
        display: inline-flex;
        align-items: center;
        margin-left: 20px;
        img {
          height: 25px;
          width: 25px;
          cursor: pointer;
        }
      }
      .timeBlockContainer {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff; // #81f0ca;
        white-space: nowrap;
        .dayBlock {
          margin-right: 10px;
        }
      }
    }
    .setUp_C {
      flex-grow: 1;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      width: calc(100% - 545px);

      .marquee-wrapper {
        flex-grow: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 1rem;
        margin: 0 1rem;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 6px;
        overflow: hidden;
        letter-spacing: 0.03rem;
        cursor: pointer;
        transition: 250ms ease;

        .icon {
          flex-shrink: 0;
          width: 25px;
          height: 25px;
          margin-right: 0.5rem;
          background-image: url('~@/assets/img/common/megaphone.png');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }
      }

      .options-wrapper {
        flex-shrink: 0;

        .margin-0 {
          margin: 0 0 0 8px;
        }
        .el-dropdown {
          font-size: 12px;
          cursor: pointer;
          @include base-fontColor();
          > span {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          i {
            margin-left: 5px;
          }
        }
        .selectLeague {
          color: white;
          cursor: pointer;
        }
      }
    }
    .setUp_R {
      flex-shrink: 0;
      width: 340px; // 275
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      .popover {
        width: 20px;
        display: flex;
        justify-content: center;
        margin: 0 5px;
      }
      i {
        font-size: 20px;
        color: #000000;
      }
      .setUp_input {
        width: 110px;
        height: 25px;
        border-radius: 3px;
        background-color: #333;
        padding: 0 10px;
        border: none;
        color: #fff;
        outline: none;
        margin-right: 8px;
      }
      .cartBtn {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        border-radius: 50rem;
        margin-right: 5px;
        padding: 2px 10px;
        background: #ffdf1b;
        cursor: pointer;
        &:hover {
          background: #e1c71a;
        }
        i {
          font-weight: bold;
        }
      }
    }
  }

  .themes-icon {
    position: absolute;
    border: 2px solid #fff;
    border-radius: 50%;
    z-index: 101;
  }
  .themes-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  .themes {
    position: absolute;
    top: 70%;
    left: -60px;
    background-color: #ccc;
    border: 2px solid #fff;
    border-radius: 12px;
    box-shadow: 2px 2px 4px #888;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    z-index: 100;
  }
</style>

<style lang="scss">
  .fastBet-help-popper {
    padding: 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    .help-title {
      color: #000;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #ccc;
      padding: 6px;
    }
    .help-detail {
      color: #000;
      font-size: 13px;
      padding: 10px 20px;
    }
  }
</style>
