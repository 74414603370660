<template>
  <div id="Games">
    <GamesHeader
      @openService="openServiceChat()"
      @openPersonal="openPersonal"
      @openSetting="
        isShowSetting = true;
        $refs.GamesSettingDialog.loadSettings();
      "
    />
    <GamesSetup
      ref="GamesSetup"
      :ok="ok"
      :isShowGameBet="isShowGameBet"
      @ClickShowGameBet="ShowGameBet"
    />
    <div class="main">
      <GamesNavMenu
        v-if="ok"
        ref="GamesNavMenu"
        @ChangeCat="ChangeCat()"
        style="height: 100%; overflow-y: auto; overflow-x: hidden"
      />
      <GamesTableList v-if="ok" @AddToCart="AddToCartEvent()" />
      <MoreGame
        v-if="isShowMoreGame"
        @AddToCart="AddToCartEvent()"
        @openPersonalPanel="isOpenPersonalPanel = true"
      />
      <GamesBetInfo
        ref="GamesBetInfo"
        v-show="isShowGameBet"
        @openSetting="
          isShowSetting = true;
          $refs.GamesSettingDialog.loadSettings();
        "
        @openStrayCount="isShowStrayCount = true"
      />
    </div>
    <GamesSettingDialog
      ref="GamesSettingDialog"
      v-show="isShowSetting"
      v-if="ok"
      @closeMe="isShowSetting = false"
    />
    <StrayCountDialog
      ref="StrayCountDialog"
      v-if="isShowStrayCount"
      @closeMe="isShowStrayCount = false"
    />
    <ServiceChat :isOpen="isOpenServiceChat" @closeMe="isOpenServiceChat = false" />
    <PersonalPanel
      v-if="isOpenPersonalPanel"
      :type="personalPanelType"
      @closeMe="isOpenPersonalPanel = false"
    />
    <ChatSocket v-if="isSiteOK" />
    <QuickBetPanel v-if="quickBetData.isShow" />
    <div id="tracing" v-if="tracingList.length">
      <div
        v-for="(item, index) in tracingList"
        :key="index"
        class="item"
        :class="getTracingClass(item)"
        @click="showTracingResult(item)"
      >
        <div v-if="item.running" v-loading="true"></div>
        {{ item.title }}
      </div>
    </div>

    <!--
      <Welcome v-if="!$store.state.siteLabels.includes('s1') && isShowWelcome" />
    -->
    <Promotion />
  </div>
</template>

<script>
  import GamesHeader from './components/GamesHeader.vue';
  import GamesSetup from './components/GamesSetup.vue';
  import GamesSettingDialog from './components/GamesSettingDialog.vue';
  import GamesNavMenu from './components/GamesNavMenu.vue';
  import GamesTableList from './components/GamesTable/GamesTableList.vue';
  import MoreGame from '../../components/MoreGame.vue';
  import GamesBetInfo from './components/GamesBetInfo/GamesBetInfo.vue';
  import StrayCountDialog from './components/StrayCountDialog.vue';
  import ServiceChat from '@/components/ServiceChat';
  import PersonalPanel from '@/components/PersonalPanel';
  import ChatSocket from '@/components/ChatSocket';
  import QuickBetPanel from '@/components/QuickBetPanel';
  import Promotion from '@/components/Promotion.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      GamesHeader,
      GamesSetup,
      GamesSettingDialog,
      StrayCountDialog,
      GamesNavMenu,
      GamesTableList,
      GamesBetInfo,
      MoreGame,
      ServiceChat,
      PersonalPanel,
      ChatSocket,
      QuickBetPanel,
      Promotion,
    },
    data() {
      return {
        isShowSetting: false,
        isShowStrayCount: false,
        isOpenServiceChat: false,
        // isShowGameBet: false,
        isOpenPersonalPanel: false,
        ok: false,
      };
    },
    computed: {
      ...mapGetters(['isSiteOK', 'isUserOk']),
      isShowGameBet: {
        get() {
          return this.$store.state.Game.isShowGameBet;
        },
        set(value) {
          this.$store.commit('Game/setShowGameBet', value);
        },
      },
      isShowMoreGame() {
        return this.$store.state.MoreGame.isShowMoreGame;
      },
      isAddNewToChart() {
        return this.$store.state.BetCart.isAddNewToChart;
      },
      quickBetData() {
        return this.$store.state.BetCart.quickBetData;
      },
      tracingList() {
        return this.$store.state.BetCart.tracingList;
      },
    },
    watch: {
      isAddNewToChart() {
        this.isShowGameBet = true;
      },
      isOpenServiceChat: {
        handler() {
          this.$store.commit('Game/setIsShowService', this.isOpenServiceChat);
        },
        immediate: true,
      },
    },
    methods: {
      ShowGameBet(val) {
        this.isShowGameBet = val;
      },
      AddToCartEvent() {
        this.$refs.GamesBetInfo.resetGroupIndex();
      },
      ChangeCat() {
        this.$refs.GamesSetup.clearLeagueList();
      },
      openServiceChat() {
        this.isOpenServiceChat = true;
      },
      openPersonal(type) {
        this.personalPanelType = type;
        this.isOpenPersonalPanel = true;
      },
      showTracingResult(item) {
        if (item.status <= 1) return;
        this.$store.commit('BetCart/addTracing');
        this.$store.commit('BetCart/showTracingItem', item);
        const data = {
          data: item.data,
          traceCodeKey: item.code,
          isStray: item.type === 99,
        };
        this.$store.dispatch('BetCart/checkBetState3', data);
        this.$nextTick(() => {
          this.$store.commit('BetCart/removeTracing', item.code);
        });
      },
      getTracingClass(item) {
        switch (item.status) {
          case 0:
            return '';
          case 1:
            return 'ok';
          case 2:
            return 'error';
          default:
            return '';
        }
      },
    },
    beforeMount() {
      const { $store } = this;
      if ($store.getters.isUserOk) {
        $store.commit('setLoading', true);
        $store.commit('Setting/init');
        Promise.all([
          $store.dispatch('Game/updateGameCatList'),
          $store.dispatch('Game/updateGameTypeList'),
          $store.dispatch('Game/updateGameMenuList'),
          $store.dispatch('updateUserBalance'),
        ]).then(() => {
          $store.commit('setLoading', false);
          this.ok = true;
        });
      } else {
        this.$router.replace({ name: 'Login' });
      }
    },
  };
</script>

<style lang="scss" scoped>
  #Games {
    width: 100%;
    height: 100%;
    min-width: 1200px;

    .main {
      width: 100%;
      height: calc(100% - 108px);
      display: flex;
    }
  }

  #tracing {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 4px;

    .item {
      display: inline-block;
      background-color: #ccc;
      border-radius: 4px;
      height: 32px;
      line-height: 32px;
      width: 64px;
      text-align: center;
      margin-left: 4px;

      &.error {
        background-color: #c03b35;
        color: #fff;
        cursor: pointer;
      }
    }
  }
</style>
